import React, {Component, Fragment} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class FooterDesktop extends Component {
    render() {
        return (
            <Fragment>

                <Container className="FooterSection">
                    <div className="row">
                        <div className="col text-center">
                            <h2 className="winning-together-heading">Winning Together</h2>
                            <div className="winning-together-lead">
                                <p>Leading organizations use our mission-critical applications to run their
                                    businesses. Find out how customers achieve sustainable, operational advantages
                                    with greater agility, critical business insights and improved productivity.</p>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col" >
                            <Card className="card-winning">
                                <div className="image-box-winning">
                                    <img src="Images/winning-together/1.png" />
                                </div>
                            </Card>
                        </Col>
                        <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                            <Card className="card-winning">
                                <div className="image-box-winning">
                                    <img src="Images/winning-together/2.png" />
                                </div>
                            </Card>
                        </Col>
                        <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                            <Card className="card-winning">

                                <div className="image-box-winning">
                                    <img src="Images/winning-together/3.png" />
                                </div>
                            </Card>
                        </Col>
                        <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                            <Card className="card-winning">

                                <div className="image-box-winning">
                                    <img src="Images/winning-together/4.png" />
                                </div>
                            </Card>
                        </Col>
                        <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                            <Card className="card-winning">

                                <div className="image-box-winning">
                                    <img src="Images/winning-together/5.png" />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>



                <div className="footer-card">
                    <Container>
                        <Row className="px-0 my-5">
                            <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="footer-content">
                                    <h2 className="footer-main-title">We Make Your Business Smoother</h2>
                                    <div className="footer-menu">
                                        <Row>
                                            <Col key={1} xl={1} lg={1} md={1} sm={1} xs={4} className="footer-col">
                                                <img src="Images/footer/footer-icon.png" />

                                            </Col>
                                            <Col key={2} xl={2} lg={2} md={2} sm={2} xs={4}>
                                                <h5 className="footer-menu-title">Our Services</h5>
                                                <div className="menu-service">
                                                    <ul className="footer-menu-1">
                                                        <li className="menu-item">
                                                            <a href="">Web Design</a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Web Development</a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Web App Development</a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Industry Tech Solutions</a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Digital Marketing</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col key={2} xl={2} lg={1} md={2} sm={2} xs={4}>
                                                <h5 className="footer-menu-title">About Us</h5>
                                                <div className="menu-service">
                                                    <ul className="footer-menu-1">
                                                        <li className="menu-item">
                                                            <a href="">Profile</a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Press Room </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Company Videos </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Partners </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Locations</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col key={2} xl={2} lg={2} md={2} sm={2} xs={4}>
                                                <h5 className="footer-menu-title">Support Center</h5>
                                                <div className="menu-service">
                                                    <ul className="footer-menu-1">
                                                        <li className="menu-item">
                                                            <a href="">Customer Support  </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Technical Support </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Server Support  </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Marketing Support </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Billing Support</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col key={2} xl={2} lg={2} md={2} sm={2} xs={4}>
                                                <h5 className="footer-menu-title">Work</h5>
                                                <div className="menu-service">
                                                    <ul className="footer-menu-1">
                                                        <li className="menu-item">
                                                            <a href="">Case Studies </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Testimonials </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Portfolio </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Blog </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">FAQ</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col key={2} xl={2} lg={2} md={2} sm={2} xs={4}>
                                                <h5 className="footer-menu-title">Other Websites</h5>
                                                <div className="menu-service">
                                                    <ul className="footer-menu-1">
                                                        <li className="menu-item">
                                                            <a href="">Insame Digital  </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Prismic Host </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Time Pack Communication  </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Daily Update Bd News </a>
                                                        </li>
                                                        <li className="menu-item">
                                                            <a href="">Farmers Point</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="footer_buttons">
                                        <div className="buttons_wrap">
                                            <div className="button_inner_wrap">
                                                <a href="#" className="button-link hvr-bounce-to-right button-Red" >
                                                    <span className="button_sub_text">For Enquires</span><br/>
                                                    <span className="button_main_text">Contact Us</span>
                                                    <i className="fa fa-arrow-alt-circle-right"></i>
                                                </a>
                                            </div>
                                            <div className="button_inner_wrap">
                                                <a className="#" className="button-link hvr-bounce-to-right button-Blue" >
                                                    <span>For Career Opportunities</span>
                                                    <span>Apply Here</span>
                                                    <i className="fa fa-arrow-alt-circle-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-menu2">
                                        <div className="footer-btn-left">
                                            <div className="footer_address">
                                                <h5 className="footer-menu-title">Bangladesh</h5>
                                                <p>
                                                    RH Home Center:
                                                    74/B/1,Suit # 338-339
                                                    Green Road,
                                                    Dhaka-1205
                                                </p>
                                            </div>
                                            <div className="footer_address">
                                                <h5 className="footer-menu-title">USA</h5>
                                                <p>
                                                    990 Harbins Rd
                                                    Norcross, Atlanta
                                                    Georgia
                                                    30093
                                                </p>
                                            </div>
                                        </div>
                                        <div className=".footer_contact_right">
                                            <div className="sm_wrap">
                                                <a href="https://www.facebook.com/insametech" className="sm_link hvr-wobble-skew"> <img src="Images/footer/facebook.svg" /> </a>
                                                <a href="https://www.youtube.com/@itimestudio7773" className="sm_link hvr-wobble-skew"> <img src="Images/footer/youtube.svg" /> </a>
                                                <a href="https://www.linkedin.com/company/insametech/" className="sm_link hvr-wobble-skew"> <img src="Images/footer/linkedin.svg" /> </a>
                                                <a href="https://instagram.com/insameltd?igshid=YzgyMTM2MGM=" className="sm_link hvr-wobble-skew"> <img src="Images/footer/instagram.svg" /> </a>

                                            </div>
                                            <div className="review-clutch">
                                                <a href="https://clutch.co/profile/insame-technologies#summary">
                                                    <img src="Images/footer/cw_clutch_reviews.webp" />

                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Container className="footer-copyright container-fluid pt-3 pb-1">
                            <h1 className="footer-copyright-text">Copyright 2023 © Insame Technologies Ltd. All rights reserved. DMCA Protected | Sitemap | Privacy Policy | Terms and Conditions </h1>
                        </Container>
                    </Container>
                </div>
            </Fragment>
        );
    }
}

export default FooterDesktop;