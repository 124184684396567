import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class OurCourses extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Our Courses</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Our Courses
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="text-center BetweenTwoSection">
                    <Row>
                        <h5 className="section-title">সহকারী জর্জ নিয়োগ পরীক্ষা সংক্রান্ত </h5>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/jarj-mcq.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">জর্জ প্রিলিমিনারী (MCQ) পরীক্ষার প্রস্তুতি </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ১৪০টি | মোট ক্লাস: ২০০টি, | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/jarj-writing.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">সহকারী জর্জ “লিখিত” পরীক্ষার প্রস্তুতি </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৮০টি | ক্লাস টেস্ট: ২০টি | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/jarj-vaiva.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">সহকারী জর্জ “ভাইভা” পরীক্ষার প্রস্তুতি </h1>
                                    <p className="programmes-short-des">ক্লাস:৪০টি | মক- ভাইভা: ০৩টি | ক্লাস সময়কাল: <br/> ১ মাস </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className="text-center BetweenTwoSection">
                    <Row>
                        <h5 className="section-title"> অ্যাডভোকেট তালিকাভূক্তি পরীক্ষা সংক্রান্ত  </h5>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barpili.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার প্রিলি (MCQ) কোর্স </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৬০টি | ক্লাস টেস্ট: ২০টি | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barlikhito.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার লিখিত কোর্স </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৪০টি | ক্লাস টেস্ট: ১০টি | মডেল টেস্ট: ০৫টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barvaiva.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার ভাইভা কোর্স </h1>
                                    <p className="programmes-short-des">ক্লাস:২০টি | মক- ভাইভা: ০৩টি | ক্লাস সময়কাল: <br/> ১ মাস </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className="text-center BetweenTwoSection">
                    <Row>
                        <h5 className="section-title">  হাইকোর্ট পারমিশন পরীক্ষা সংক্রান্ত   </h5>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/high-court-written.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">হাইকোর্ট পারমিশন লিখিত কোর্স</h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৩০টি | ক্লাস টেস্ট: ০৭টি | মডেল টেস্ট: ০৩টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/highcourt-viva.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">হাইকোর্ট পারমিশন ভাইভা কোর্স</h1>
                                    <p className="programmes-short-des">ক্লাস: ২০টি |মক-ভাইভা: ০৩টি | ক্লাস সময়কাল: <br/> ১ মাস </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container className="text-center BetweenTwoSection">
                    <Row>
                        <h5 className="section-title">  ল’ অফিসার পরীক্ষা সংক্রান্ত    </h5>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/law-officer-written.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">ল’ অফিসার “লিখিত” পরীক্ষার প্রস্তুতি  </h1>
                                    <p className="programmes-short-des">ক্লাস: ৩৫টি | ক্লাস টেস্ট: ১০টি | মডেল টেস্ট: <br/>০৫ টি  </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/law-officer-viva.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">ল’ অফিসার ভাইভা পরীক্ষার প্রস্তুতি কোর্স  </h1>
                                    <p className="programmes-short-des">ক্লাস: ২০টি |মক-ভাইভা: ০২টি | ক্লাস সময়কাল: <br/> ১ মাস  </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <Link to="/coursedetails"><Button className="course-button-left" variant="danger">View Details</Button></Link>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
    }
}

export default OurCourses;