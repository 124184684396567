import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

class AboutSection extends Component {
    render() {
        return (
            <Fragment>
                <Container  fluid={true}>
                    <Row>
                        <div id="content-tiles" className="layer layer-tiles layer-darkText insameosbg">
                            <div className="tileLeadinContainer">

                                <div className="col">
                                    <div className="layer insametop">
                                        <div className="container">
                                            <div className="row ">
                                               <div className="col text-center">
                                                   <h2 className="choose-ur-plateform">Choose Your Platform</h2>
                                                   <div className="lead">
                                                   <p>Discover Your Business Identity in the Age of Transformation</p>
                                                   </div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Row>

                </Container>
                <Container fluid={true}>
                    <Row >
                        <Col key={1} xl={4} lg={4} md={4} sm={4} xs={12} className="soft-hard-mar">
                            <div className="i_software_main_div" data-anim-desktop="fadeInUp">
                                <div className="i_software_title" data-anim-tablet="fadeInLeft"
                                     data-element-type="paragraph"
                                     data-version="5" >
                                    <h4 className="text-align-center m-text-align-center span-soft">
                                        Software
                                    </h4>
                                </div>
                                <div className="i_software_hr" data-anim-tablet="fadeInLeft"
                                     data-element-type="dDividerId">
                                    <hr className="dmDivider"/>
                                </div>
                                <div className="i_software_NewParagraph" data-anim-tablet="fadeInLeft"
                                     data-version="5">
                                    <p className="m-text-align-center text-align-center">
                                        Insame’s specialists will
                                        determine the best combination, customization and integration of the any kind of
                                        software,
                                        hardware and technology to meet your unique business needs.
                                    </p>

                                </div>
                                <a className="software-take-look" href="#">Take a Look</a>
                            </div>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={4} sm={4} xs={12} className="soft-hard-mar">
                            <div className="i_hardware_main_div">
                                <div className="i_software_title" data-anim-tablet="fadeInLeft"
                                     data-element-type="paragraph"
                                     data-version="5">
                                    <h4 className="text-align-center m-text-align-center span-soft">
                                        Hardware
                                    </h4>
                                </div>
                                <div className="i_software_hr" data-anim-tablet="fadeInLeft"
                                     data-element-type="dDividerId"
                                     layout="divider-style-1" data-widget-version="2">
                                    <hr className="dmDivider"/>
                                </div>
                                <div className="i_software_NewParagraph" data-anim-tablet="fadeInLeft"
                                     data-version="5">
                                    <p className="text-align-center m-text-align-center">
                                <span>We are providing best quality
                                    hardware products to our customers also most reasonable price.Products:
                                    Computers Parts, Office Equipment, Gaming PC, Security Camera & many other.</span>
                                    </p>
                                </div>
                                <a className="software-take-look" href="#">Take a Look</a>
                            </div>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={4} sm={4} xs={12} className="soft-hard-mar">
                            <div className="i_marketing_main_div" data-anim-desktop="fadeInUp">
                                <div className="i_software_title" data-anim-tablet="fadeInLeft"
                                     data-element-type="paragraph"
                                     data-version="5">
                                    <h4 className="text-align-center span-soft">
                                        Marketing
                                    </h4>
                                </div>
                                <div className="i_software_hr" data-anim-tablet="fadeInLeft"
                                     data-element-type="dDividerId">
                                    <hr className="dmDivider"/>
                                </div>
                                <div className="i_software_NewParagraph"
                                     data-anim-tablet="fadeInLeft" data-version="5">
                                    <p className="text-align-center m-text-align-center">
                                <span>Marketing and branding with
                                    insame is not just a one-and-done project-based service. Instead, we focus on
                                    long-term strategies that allow your brand amplification to develop over
                                    time.</span>
                                    </p>

                                </div>
                                <a className="software-take-look" href="#">Take a Look</a>
                            </div>
                        </Col>

                    </Row>
                </Container>

                <Container fluid={true}>
                    <Row >
                        <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar insame-solutions-section">
                            <div className="ss-header text-center ">
                                <h1 className="feature-title-solutions h2">Solutions</h1>
                                <p className="feature-text-solutions">Whether you are a clinic, hospital, a telemedicine
                                    start-up, an insurance or pharma company, the Virtual Practice can offer you a solution
                                </p>
                            </div>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar1">
                            <Row>
                                <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar">
                                    <div className="ss-content-col">
                                        <a href="#"><span className="insame-solutions-details-span">FinTech</span>
                                            <picture>
                                                <source media="(min-width: 768px)" srcSet="Images/solutions/fintech.jpg"/>
                                                <source media="(max-width: 576px)" srcSet="Images/solutions/fintech.jpg"/>
                                                <img data-src="Images/solutions/fintech.jpg" className="solution-img" alt=""/>
                                            </picture>
                                        </a>
                                    </div>
                                </Col>
                                <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar">
                                    <div className="ss-content-col">
                                        <a href="#"><span className="insame-solutions-details-span">EduTech</span>
                                            <picture>

                                                <source media="(min-width: 768px)"
                                                        srcSet="Images/solutions/fintech.jpg"/>
                                                <source media="(max-width: 576px)"
                                                        srcSet="Images/solutions/fintech.jpg"/>

                                                <img data-src="Images/solutions/fintech.jpg" className="solution-img" alt=""/>
                                            </picture>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar">
                                    <div className="ss-content-col ">
                                    <a href="#"><span className="insame-solutions-details-span">HealthTech</span>
                                        <picture>

                                            <source media="(min-width: 768px)"
                                                    srcSet="Images/solutions/health-tech.jpg"/>
                                            <source media="(max-width: 576px)"
                                                    srcSet="Images/solutions/health-tech.jpg"/>

                                            <img data-src="Images/solutions/health-tech.jpg" className="solution-img" alt=""/>
                                        </picture>
                                    </a>
                                  </div>
                                </Col>
                                <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} className="soft-hard-mar">
                                    <div className="ss-content-col ">
                                        <a href="#"><span className="insame-solutions-details-span">RealTech</span>
                                            <picture>

                                                <source media="(min-width: 768px)"
                                                        srcSet="Images/solutions/real-tech.jpg"/>
                                                <source media="(max-width: 576px)"
                                                        srcSet="Images/solutions/real-tech.jpg"/>

                                                <img data-src="Images/solutions/real-tech.jpg" className="solution-img" alt=""/>
                                            </picture>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>


            </Fragment>
        );
    }
}

export default AboutSection;