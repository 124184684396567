import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";

class BarVaiva extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>বার লিখিত কোর্স </h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <Link to="/" >Home</Link>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            বার লিখিত কোর্স
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <Row className="p-1">
                        <Col className="shadow-sm bg-white pb-3 mt-4" md={12} lg={12} sm={12} xs={12}>
                            <Row>
                                <Col className="p-3" md={6} lg={6}sm={12} xs={12}>
                                    <img className="image-box-gallery" src="Images/courses/barvaiva.jpg" />

                                </Col>
                                <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
                                    <h5 className="Product-Name">বার কাউন্সিল “ভাইভা” পরীক্ষার প্রস্তুতি কোর্স</h5>
                                    <h6 className="section-title">  About this course</h6>
                                    <div className="mt-15 course-description">
                                        <p className="d-flex align-items-start font-14 text-gray mt-10">
                                            <i className="fas h1 fa-check course-details-icon" ></i>
                                            <span className="">ক্লাস: ২০টি</span>
                                        </p>
                                        <p className="d-flex align-items-start font-14 text-gray mt-10">
                                            <i className="fas h1 fa-check course-details-icon" ></i>
                                            <span className="">ভাইভা: ০৩টি</span>
                                        </p>
                                        <p className="d-flex align-items-start font-14 text-gray mt-10">
                                            <i className="fas h1 fa-check course-details-icon" ></i>
                                            <span className="">কোর্স Duration: ১ মাস</span>
                                        </p>
                                        <p className="d-flex align-items-start font-14 text-gray mt-10">
                                            <i className="fas h1 fa-check course-details-icon" ></i>
                                            <span className="">কোর্স ফি :অফলাইন: <span className="price-crs"> ৩,০০০/- (এককালীন) </span>
                                        </span>
                                        </p>
                                    </div>
                                    <Button className="enroll-button" variant="danger">Enroll Now</Button>
                                    <Button className="course-details-button" variant="danger">Add To Cart</Button>
                                </Col>

                            </Row>
                            <Row>
                                <Col className=" p-e" md={6} lg={6} sm={12} xs={12}>
                                    <h5 className="about-title">শিক্ষার্থীদের জন্য করণীয়</h5>
                                    <p className="about-content">- ভর্তিচ্ছু প্রর্থীকে ভর্তির সময় ২কপি পাসপোর্ট সাইজ রঙ্গিন ছবি অবশ্যই জমা দিতে হবে। </p>
                                    <p className="about-content">- ৮০% ক্লাসে উপস্থিত থাকতে হবে। </p>
                                    <p className="about-content">- ক্লাস টেস্টে অবশ্যই অংশগ্রহণ করতে হবে এবং পরীক্ষার সময় শৃঙ্খলা মেনে চলতে হবে। </p>
                                    <p className="about-content">- প্রিলিমিনারি, লিখিত ও ভাইভা কোর্সে আলাদা ভাবে ভর্তি প্রযোজ্য। </p>
                                    <p className="about-content">- শৃঙ্খলা পরিপন্থি কাজের জন্য কর্তৃপক্ষ চাইলে যে কোন শিক্ষার্থীর ভর্তি বাতিল করতে
                                        পারবে। </p>
                                    <p className="about-content">- কর্তৃপক্ষ চাইলে এই নিয়মাবলি শিথিল করতে পারবে। </p>
                                </Col>
                                <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
                                    <h6> Review</h6>
                                    <p className="p-0 m-0"><span className="Review-Title">Israt </span><i className="fa fa-star" ></i><i className="fa fa-star" ></i><i className="fa fa-star" ></i><i className="fa fa-star" ></i></p>
                                    <p> </p>
                                    <p className="p-0 m-0"><span className="Review-Title">Jahan </span><i className="fa fa-star" ></i><i className="fa fa-star" ></i><i className="fa fa-star" ></i><i className="fa fa-star" ></i></p>
                                    <p> </p>

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default BarVaiva;