import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import MegaMenu from "./MegaMenu";
import Slider from "react-slick";
import BigSlider from "./BigSlider";

class HomeTop extends Component {
    render() {
        return (
            <Fragment>
                <Container className="p-0 m-0 overflow-hidden "fluid={true}>
                    <Row>
                        <BigSlider/>

                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default HomeTop;