import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";

class Contact extends Component {


    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Contact Us</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Contact Us
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className=" text-center BetweenTwoSection ">
                    <Row>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} className="stat-bg">
                            <Card className="image-box-icon card " >
                                <i className="fa h4 fa-location-arrow contact-location"></i>
                                <Card.Body>
                                    <h5 className="stat-title">Address</h5>
                                    <p className="stat-desc">74/B/1, Suit-219, 3rd floor (Lift-2),<br/>
                                        RH-Homr Center, Green Road, Faramget</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} className="stat-bg">
                            <Card className="image-box-icon-2 card " >
                                <i className="fa h4 fa-phone contact-phone" ></i>
                                <Card.Body>
                                    <h5 className="stat-title">Phone</h5>
                                    <p className="stat-desc">+880 1622-241004<br/>
                                        +880 1738-901381</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} className="stat-bg">
                            <Card className="image-box-icon-3 card " >
                                <i className="fa h4 fa-mail-bulk contact-email"></i>
                                <Card.Body>
                                    <h5 className="stat-title">Email </h5>
                                    <p className="stat-desc">haquelawacademy@gmail.com<br/>
                                        info@haquelawacademy.com</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col className="BetweenTwoSection" md={6} lg={6} sm={8} xs={12}>
                            <Card className="contact-card">
                                <Card.Body>
                                    <Card.Title>Contact With Us</Card.Title>
                                    <p className="text-center login-subtitle">Please Enter Your Mobile No & Go Next </p>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="Your Name" />
                                        </Form.Group >
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="Your Email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" placeholder="Your Message" />
                                        </Form.Group>
                                    </Form>
                                    <Button className="login-next-btn" variant="primary">Submit</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="BetweenTwoSection" md={6} lg={6} sm={8} xs={12}>
                            <Card>
                                <Card.Body>
                                    <iframe className="GoogleMap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.7995576377775!2d90.38673961438849!3d23.754526194536854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9965114e8d1%3A0x6d193b34899843af!2sHaque%20Law%20Academy%20(Lighthouse%20Of%20Legal%20Education)!5e0!3m2!1sbn!2sbd!4v1672742679174!5m2!1sbn!2sbd">


                                    </iframe>


                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default Contact;