import React, {Component, Fragment} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import AboutSection from "../home/AboutSection";

class AboutUs extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>About Us</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            About Us
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Container>
                    <AboutSection/>

                </Container>
                <Container className="text-center BetweenTwoSection">
                    <Row>
                        <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12} >
                            <div className="about-des">
                                <h5 className="about-des-title"> হক ল” একাডেমির বৈশিষ্ট্য সমূহ: </h5>
                                <p className="about-content">- আইন জগতের সেরা জজ/ ম্যাজিস্ট্রেট/ বিসিএস ক্যাডারদের
                                    দ্বারা ক্লাস গ্রহণ।
                                </p>
                                <p className="about-content">- ক্লাস অনলাইন ও অফলাইন তথা উভয় ভাবে নেয়া হয়। </p>
                                <p className="about-content">- চাকুরিজীবীদের জন্য সন্ধ্যায়, ছুটির দিনে ও সুবিধাজনক সময়ে
                                    ক্লাসের
                                    সু-ব্যবস্থা। </p>
                                <p className="about-content">- সর্বাধিক ক্লাস এবং পরীক্ষা। </p>
                                <p className="about-content">- প্রত্যেক ক্লাসের শুরুতে ক্লাস টেস্ট এবং সাবলীল ব্যাখ্যাসহ
                                    সমাধান ক্লাস
                                    নেওয়া হয়। </p>
                                <p className="about-content">- বার কাউন্সিল পরীক্ষার্থীদের জন্য জজ এবং সুপ্রিম কোর্টের
                                    অভিজ্ঞ
                                    অ্যাডভোকেটদের দ্বারা ক্লাস গ্রহণ। </p>
                                <p className="about-content">- ডিজিটাল ও শীতাতপ নিয়ন্ত্রিত ক্লাসরুম। </p>
                                <p className="about-content">- কোর্সের শুরুতে শিক্ষকগণ কর্র্তৃক সকল বিষয়ের কোর্স প্ল্যান
                                    শিক্ষার্থীদের
                                    সরবরাহ করা। </p>
                                <p className="about-content">- গণিত ও ইংরেজীতে যারা দুর্বল তাদের জন্য বিশেষ
                                    ব্যবস্থা। </p>
                                <p className="about-content">- সর্বোপরি, পরীক্ষায় নিশ্চিত সাফল্যের জন্য এক্সক্লুসিভ
                                    সাজেশন প্রদান
                                    ইত্যাদি। </p>
                            </div>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12} >
                            <div className="about-des">
                                <h5 className="about-des-title">
                                    শিক্ষার্থীদের জন্য করণীয়:
                                </h5>
                                <p className="about-content">- ভর্তিচ্ছু প্রর্থীকে ভর্তির সময় ২কপি পাসপোর্ট সাইজ রঙ্গিন ছবি অবশ্যই জমা দিতে হবে।
                                </p>
                                <p className="about-content">- ৮০% ক্লাসে উপস্থিত থাকতে হবে। </p>
                                <p className="about-content">- ক্লাস টেস্টে অবশ্যই অংশগ্রহণ করতে হবে এবং পরীক্ষার সময় শৃঙ্খলা মেনে চলতে হবে।  </p>
                                <p className="about-content">- প্রিলিমিনারি, লিখিত ও ভাইভা কোর্সে আলাদা ভাবে ভর্তি প্রযোজ্য।  </p>
                                <p className="about-content">- শৃঙ্খলা পরিপন্থি কাজের জন্য কর্তৃপক্ষ চাইলে যে কোন শিক্ষার্থীর ভর্তি বাতিল করতে পারবে। </p>
                                <p className="about-content">- কর্তৃপক্ষ চাইলে এই নিয়মাবলি শিথিল করতে পারবে। </p>
                                <p className="about-content">- ডিজিটাল ও শীতাতপ নিয়ন্ত্রিত ক্লাসরুম। </p>
                                <p className="about-content">- ডিজিটাল ও শীতাতপ নিয়ন্ত্রিত ক্লাসরুম।  </p>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default AboutUs;