import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

class AdmissionInfo extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Admission Info</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Admission Info
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="BetweenTwoSection" >
                    <Row>
                        <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12} >
                            <div className="admission-info-des">

                                <h5 className="about-title">শিক্ষার্থীদের জন্য করণীয়</h5>
                                <p className="about-content">- ভর্তিচ্ছু প্রর্থীকে ভর্তির সময় ২কপি পাসপোর্ট সাইজ রঙ্গিন ছবি অবশ্যই জমা দিতে হবে। </p>
                                <p className="about-content">- ৮০% ক্লাসে উপস্থিত থাকতে হবে। </p>
                                <p className="about-content">- ক্লাস টেস্টে অবশ্যই অংশগ্রহণ করতে হবে এবং পরীক্ষার সময় শৃঙ্খলা মেনে চলতে হবে। </p>
                                <p className="about-content">- প্রিলিমিনারি, লিখিত ও ভাইভা কোর্সে আলাদা ভাবে ভর্তি প্রযোজ্য। </p>
                                <p className="about-content">- শৃঙ্খলা পরিপন্থি কাজের জন্য কর্তৃপক্ষ চাইলে যে কোন শিক্ষার্থীর ভর্তি বাতিল করতে
                                    পারবে। </p>
                                <p className="about-content">- কর্তৃপক্ষ চাইলে এই নিয়মাবলি শিথিল করতে পারবে। </p>

                            </div>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12} >
                            <div className="admission-info-des image-box-pro-about">
                                <img src="Images/admissioninfo.png" />

                            </div>
                        </Col>


                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default AdmissionInfo;