import React, {Component, Fragment} from 'react';
import Hardware from "../components/home/Hardware";
import Services from "../components/home/Services";
import AboutSection from "../components/home/AboutSection";

import BigSlider from "../components/home/BigSlider";
import HomeTop from "../components/home/HomeTop";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import FooterDesktop from "../components/common/FooterDesktop";
import IndustriesWeProvide from "../components/home/IndustriesWeProvide";


class HomePage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>

                <div className="Desktop">
                    <NavMenuDesktop/>
                    <HomeTop/>
                </div>
                <div className="Mobile">
                    <NavMenuMobile/>
                    <HomeTop/>
                </div>
                <AboutSection/>
                <Services/>
                <Hardware/>
                <IndustriesWeProvide/>
                <FooterDesktop/>


            </Fragment>
        );
    }
}

export default HomePage;
