import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class NavMenuMobile extends Component {
    constructor() {
        super();
        this.state={
            SideNavState:"sideNavClose",
            ContentOverState:"ContentOverlayClose"
        }
    }


    MenuBarClickHandler=()=>{
        this.SideNavOpenClose();

    }
    ContentOverlayClickHandler=()=>{
        this.SideNavOpenClose();


    }

    SideNavOpenClose=()=>{

        let SideNavState= this.state.SideNavState;
        let ContentOverState= this.state.ContentOverState;
        if(SideNavState==="sideNavOpen"){
            this.setState({SideNavState:"sideNavClose", ContentOverState: "ContentOverlayClose"})
        }
        else{
            this.setState({SideNavState:"sideNavOpen", ContentOverState: "ContentOverlayOpen"})
        }

    }





    render() {
        return (
            <Fragment>
                <Navbar fixed={"top"} className="navbar" bg="light" expand="lg">
                    <Container className="menu-desktop">
                        <Row>
                            <Col  lg={2} md={2} sm={2} xs={2}>
                                <Button onClick={this.MenuBarClickHandler} className="btn mobilenavbarcss"><i className="fa h4 fa-bars"></i> </Button>

                            </Col>
                            <Col  lg={7} md={7} sm={7} xs={7}>
                                <a href="#" className="text-center btn logo-top-mobilee" > <img className="nav-logo-mobile" src="Images/website-logo.png"/></a>

                            </Col>
                            <Col  lg={3} md={3} sm={3} xs={3}>
                                <Button className="cart-btn cart-top-mobile"><i className="fa h4 fa-shopping-cart"></i> 4 </Button>

                            </Col>


                        </Row>

                    </Container>
                </Navbar>
                <Container>
                    <div  className={this.state.SideNavState}>
                        <div>
                            <Link to="/" className="list-group-item nav-font nav-itemmenuu list-group-item-action" >Home</Link>
                            <Link to="/courses" className="list-group-item nav-font nav-itemmenu list-group-item-action" >Our Courses</Link>
                            <Link to="/admission" className="list-group-item nav-font nav-itemmenu list-group-item-action" >Admission Info</Link>
                            <Link to="/about" className="list-group-item nav-font nav-itemmenu list-group-item-action"  >About US</Link>
                            <Link to="/gallery" className="list-group-item nav-font nav-itemmenu list-group-item-action" >Gallery</Link>
                            <Link to="/contact" className="list-group-item nav-font nav-itemmenu list-group-item-action"  >Contact Us</Link>
                            <a className="list-group-item nav-font nav-itemmenu list-group-item-action" href="#" >Book Shop</a>
                            <Link to="/onboard" className="h4 btn cart-top login-btnn-mobile">Login</Link>
                            <Link className="h4 btn cart-top register-btnn-mobile">Register</Link>
                        </div>
                    </div>
                    <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>

                    </div>
                </Container>


            </Fragment>
        );
    }
}

export default NavMenuMobile;