import React, {Component,Fragment} from 'react';
import {Routes, Route} from "react-router-dom";
import HomePage from "../pages/HomePage";
import UserOnBoardPage from "../pages/UserOnBoardPage";
import ContactPage from "../pages/ContactPage";
import AboutUsPage from "../pages/AboutUsPage";
import AdmissionInfoPage from "../pages/AdmissionInfoPage";
import GalleryPage from "../pages/GalleryPage";
import OurCoursesPage from "../pages/OurCoursesPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsOfUsePage from "../pages/TermsOfUsePage";
import CourseDetailsPage from "../pages/CourseDetailsPage";
import RegisterPage from "../pages/RegisterPage";
import NotificationsPage from "../pages/NotificationsPage";
import BarPiliPage from "../pages/BarPiliPage";
import BarVaivaPage from "../pages/BarVaivaPage";
import FavouritePage from "../pages/FavouritePage";



class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/onboard' element={<UserOnBoardPage />} />
                    <Route path='/contact' element={<ContactPage />} />
                    <Route path='/about' element={<AboutUsPage />} />
                    <Route path='/admission' element={<AdmissionInfoPage />} />
                    <Route path='/gallery' element={<GalleryPage />} />
                    <Route path='/courses' element={<OurCoursesPage />} />
                    <Route path='/privacy' element={<PrivacyPolicyPage />} />
                    <Route path='/terms' element={<TermsOfUsePage />} />
                    <Route path='/coursedetails' element={<CourseDetailsPage />} />
                    <Route path='/register' element={<RegisterPage />} />
                    <Route path='/notification' element={<NotificationsPage />} />

                    <Route path='/barpili' element={<BarPiliPage />} />
                    <Route path='/barvaiva' element={<BarVaivaPage />} />

                    <Route path='/favourite' element={<FavouritePage />} />
                </Routes>
            </Fragment>
        );
    }
}

export default AppRoute;