import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class NavMenuDesktop extends Component {
    render() {
        return (
            <Fragment>
                <header className="primary" fixed={"top"}>
                    <Container>
                         <Navbar fixed={"top"}  className="navbar" expand="lg">
                             <a className="navbar-brand" href="/">
                                 <img className="navbar-brand__image -hideScrolled" src="Images/logo-insame.png"
                                      alt="Insame Technologies Limited | One Platform. Many Missions."/>
                             </a>
                             <Nav id="top-nav" className="collapse navbar-collapse justify-content-lg-start">
                                 <ul className="navbar-nav nav-primary">
                                     <li className="nav-item dropdown">
                                         <NavDropdown title="Software Solutions" id="basic-nav-dropdown" className="nav-item dropdown">
                                             <Row className="mega-menu-width">
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col col-quarter">
                                                         <div className="dropdown-title">
                                                             Software Solutions
                                                          </div>
                                                         <hr className="heading-tag"/>
                                                         <div className="dropdown-banner">
                                                         <h2 className="header-mobile-com">Software, Simplified</h2>
                                                         <p className="copy-mobile-des11">We can develop reliable,
                                                             scalable and secure software solutions for any OS,
                                                             browser and device.</p>
                                                         <p className="copy-mobile-des11">Can't find what you need?</p>
                                                         <a className="red-btn-mobile-ask-us" href="#">Ask us</a>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">FinTech</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Accounting Solution</a>
                                                         <a className="dropdown-sublink" href="#">Invoice / Billing</a>
                                                         <a className="dropdown-sublink" href="#">Point of Sale</a>
                                                         <a className="dropdown-sublink" href="#">TAX & VAT Solution</a>
                                                         <div className="dropdown-title  mega-menu-space1"> Consumer Manufacturing </div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Restaurant Management </a>
                                                         <a className="dropdown-sublink" href="#">Food & Beverage Management </a>
                                                         <a className="dropdown-sublink" href="#">Food Service Distribution Management</a>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">HealthTech</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Pharmacy Management</a>
                                                         <a className="dropdown-sublink" href="#">Doctor Appointment Management</a>
                                                         <a className="dropdown-sublink" href="#">Diagnosis Management system</a>
                                                         <a className="dropdown-sublink" href="#">Hospital Management System</a>
                                                         <div className="dropdown-title  mega-menu-space1"> RealTech </div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Real Estate ERP </a>
                                                         <a className="dropdown-sublink" href="#">Buy And Selling Software</a>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">EdTech</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">HR & Payroll</a>
                                                         <a className="dropdown-sublink" href="#">Educational ERP</a>
                                                         <a className="dropdown-sublink" href="#">Education CMS Development</a>
                                                         <a className="dropdown-sublink" href="#">Education App Development</a>
                                                         <div className="dropdown-title  mega-menu-space1"> Web Solutions </div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Ecommerce Website</a>
                                                         <a className="dropdown-sublink" href="#">Custom Web Development </a>
                                                         <a className="dropdown-sublink" href="#">News Portal </a>
                                                     </div>
                                                 </Col>
                                             </Row>
                                         </NavDropdown>
                                     </li>

                                     <li className="nav-item dropdown">
                                         <NavDropdown title="Services" id="basic-nav-dropdown" className="nav-item dropdown">
                                             <Row className="mega-menu-width">
                                                 <Col  lg={2} md={2} sm={6} xs={6} className="mega-menu-divider2">
                                                     <div className="col col-quarter">
                                                         <div className="dropdown-banner-mobile">
                                                             <h2 className="header-mobile-com">Managed Services</h2>
                                                             <hr className="heading-tag"/>
                                                                 <p className="copy-mobile-des">Enterprise-level
                                                                     processes,
                                                                     technology and strategy for small and medium
                                                                     businesses.
                                                                 </p>
                                                                 <a className="red-btn-mobile-more" href="#">See Our
                                                                     Approach</a>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={2} md={2} sm={6} xs={6} className="mega-menu-divider2">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">Insame Cloud Solutions</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Share Hosting</a>
                                                         <a className="dropdown-sublink" href="#">Dedicated Server</a>
                                                         <a className="dropdown-sublink" href="#">Manage Server</a>
                                                         <a className="dropdown-sublink" href="#">Cloud Space</a>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={2} md={2} sm={6} xs={6} className="mega-menu-divider2">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">Domain Service</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Domain Reseller</a>
                                                         <a className="dropdown-sublink" href="#">Domain Registration</a>
                                                         <a className="dropdown-sublink" href="#">Domain Transfer</a>
                                                         <a className="dropdown-sublink" href="#">SSL Certificate & Site lock</a>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={2} md={2} sm={6} xs={6} className="mega-menu-divider2">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">Digital Innovation</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Social Marketing
                                                             Solution</a>
                                                         <a className="dropdown-sublink" href="#">Design & Creative
                                                             Support</a>
                                                         <a className="dropdown-sublink" href="#">Logistics & Office
                                                             Supplies
                                                         </a>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={2} md={2} sm={6} xs={6} className="mega-menu-divider2">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <div className="dropdown-title">Business Launching</div>
                                                         <hr className="heading-tag"/>
                                                         <a className="dropdown-sublink" href="#">Incorporation
                                                             Services</a>
                                                         <a className="dropdown-sublink" href="#">Consultation
                                                         </a>
                                                         <a className="dropdown-sublink" href="#">Recruitment</a>
                                                     </div>
                                                 </Col>
                                             </Row>
                                         </NavDropdown>


                                     </li>
                                     <li className="nav-item dropdown">
                                         <NavDropdown title="About Us" id="basic-nav-dropdown" className="nav-item dropdown">
                                             <Row className="mega-menu-width">
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col col-quarter">
                                                         <div className="dropdown-title">
                                                             11 Years in the Making
                                                         </div>
                                                         <hr className="heading-tag"/>
                                                         <div className="dropdown-banner-mobile">
                                                             <p className="copy-mobile-des">Learn more about INSAME
                                                                 Software,
                                                                 our team
                                                                 and history.</p>
                                                             <a className="red-btn-mobile-more" href="#">Learn More
                                                                 About
                                                                 Insame</a>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Press Room
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Press releases and company
                                                             announcements</p>
                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Company Videos
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Events, partnerships and community
                                                             engagement</p>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Locations
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Information about Insame's
                                                             branches</p>

                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Partners
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Insame's technology and equipment
                                                             providers</p>
                                                     </div>
                                                 </Col>
                                                 <Col  lg={3} md={3} sm={6} xs={6} className="mega-menu-divider">
                                                     <div className="col-quarter dropdown-noHeader">
                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Leadership
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Insame executives and department
                                                             heads
                                                         </p>
                                                         <a href="#">
                                                             <div className="dropdown-title">
                                                                 Recognition
                                                             </div>
                                                         </a>
                                                         <p className="copy-desk-des">Insame all Awards and
                                                             Testimonials</p>
                                                     </div>
                                                 </Col>
                                             </Row>
                                         </NavDropdown>


                                     </li>
                                     <li className="nav-item dropdown">
                                         <a className="nav-link" href="#" aria-haspopup="true"
                                            aria-expanded="false">Platform</a>
                                     </li>
                                     <li className="nav-item dropdown">
                                         <a className="nav-link" href="#" aria-haspopup="true"
                                            aria-expanded="false">Partners</a>
                                     </li>
                                     <li className="nav-item dropdown">
                                         <a className="nav-link" href="#" aria-haspopup="true"
                                            aria-expanded="false">Careers</a>

                                     </li>

                                 </ul>
                                 <ul className="navbar-nav new-utility">
                                     <li>
                                         <a className="nav-link icon__resource" href="#">Resources</a>
                                     </li>

                                     <li>
                                         <a className="nav-link icon__resource" href="#">Support Ticket</a>
                                     </li>

                                     <li>
                                         <a className="nav-link icon__careers" href="#">Support Inquiries</a>
                                     </li>
                                 </ul>
                                 <div className="header-action-software">
                                     <a href="#" className="btn btn-outline-software btn-rounded">See Our
                                         Software</a>
                                 </div>

                                 <div className="header-action">
                                     <a href="#" className="btn btn-accent btn-icon btn-rounded">Need Hardware</a>
                                 </div>
                             </Nav>

                         </Navbar>
                    </Container>
                </header>
            </Fragment>
        );
    }
}

export default NavMenuDesktop;