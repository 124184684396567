import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class Services extends Component {
    render() {
        return (
            <Fragment>

                <div className="real-time">
                    <Container>
                        <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <div>
                                <div className="row">
                                    <div className="col  text-center">
                                        <h2 className="layer-heading">Real-time value at work</h2>
                                    </div>
                                </div>
                                <div className="col fullWidth__text text-center">
                                    <p className="real-time-lead ">Our comprehensive and flexible solutions are preconfigured to meet the
                                        needs of your
                                        business. With industry-specific capabilities and user roles built in. All delivered on
                                        an extensible platform with world-class security. That's ERP simplified.</p>
                                    <p><a href="#" className="real-time-lead-btn">Read More</a></p>
                                </div>
                            </div>
                        </Col>
                    </Container>
                </div>

                <Container fluid={true} className="service-bg">
                    <div className="col text-center">
                        <h2 className="choose-ur-plateform">Our Services</h2>
                        <div className="lead">
                            <p>Discover Your Business Identity in the Age of Transformation</p>
                        </div>
                    </div>
                    <Row >
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar ">
                            <div className="text-center">
                                <a href="#">
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet="Images/services/user-experience.avif"/>
                                        <source media="(max-width: 576px)" srcSet="Images/services/user-experience.avif"/>
                                        <img data-src="Images/services/user-experience.avif" className="solution-img" alt=""/>
                                    </picture>
                                </a>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar service2">
                            <div className=" text-center ">
                                <div className="service-part">
                                    <img className="service-icon" src="Images/services/user-experience.png" />
                                    <h1 className="service-title">Solutions</h1>
                                    <p className="service-text">A product can gain more sales if the UX is exciting and easy to understand.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar">
                            <div className="text-center">
                                <a href="#">
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet="Images/services/user-experience.avif"/>
                                        <source media="(max-width: 576px)" srcSet="Images/services/user-experience.avif"/>
                                        <img data-src="Images/services/user-experience.avif" className="solution-img" alt=""/>
                                    </picture>
                                </a>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar service2">
                            <div className=" text-center ">
                                <div className="service-part">
                                    <img className="service-icon" src="Images/services/branding.png" />
                                    <h1 className="service-title">Branding</h1>
                                    <p className="service-text">A product can gain more sales if the UX is exciting and easy to understand.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar service2">
                            <div className=" text-center ">
                                <div className="service-part">
                                    <img className="service-icon" src="Images/services/ui-design.png" />
                                    <h1 className="service-title">UI Design</h1>
                                    <p className="service-text">A product can gain more sales if the UX is exciting and easy to understand.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar">
                            <div className="text-center">
                                <a href="#">
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet="Images/services/user-experience.avif"/>
                                        <source media="(max-width: 576px)" srcSet="Images/services/user-experience.avif"/>
                                        <img data-src="Images/services/user-experience.avif" className="solution-img" alt=""/>
                                    </picture>
                                </a>
                            </div>
                        </Col>


                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar service2">
                            <div className=" text-center ">
                                <div className="service-part">
                                    <img className="service-icon" src="Images/services/animation.png" />
                                    <h1 className="service-title">Animation</h1>
                                    <p className="service-text">A product can gain more sales if the UX is exciting and easy to understand.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={3} xs={12} className="soft-hard-mar">
                            <div className="text-center">
                                <a href="#">
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet="Images/services/user-experience.avif"/>
                                        <source media="(max-width: 576px)" srcSet="Images/services/user-experience.avif"/>
                                        <img data-src="Images/services/user-experience.avif" className="solution-img" alt=""/>
                                    </picture>
                                </a>
                            </div>
                        </Col>

                    </Row>
                </Container>



                <div className="wing-creative">
                   <Container  className="wing-creative-div">
                       <Row>
                           <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Card className="image-box-service card" >
                                <Card.Body className="wing-creative-body" >
                                    <Row>
                                        <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} >
                                        <div className="feature">
                                            <h2 className="service-eyebrow">
                                                <p>We speak your language </p>
                                            </h2>
                                        </div>
                                        <h3 className="service-title-h2">
                                            <p>Meet Our <span className="digital-wing">Creative</span>  Wing</p>
                                            <p className="service-eyebrow">Making People Attention </p>

                                        </h3>

                                        <div className="feature-text">
                                            <p>If you couldn’t attend Insame Digital, there’s still time to access all of the on-demand content. Learn from our experts and hear how our customers are driving success.</p>
                                        </div>
                                        <div className="feature-action">
                                            <a href="#" className="wing-creative-btn"
                                               target="_self"> Visit Website <li className="far fa-arrow-alt-circle-right"></li></a>
                                        </div>

                                    </Col>
                                        <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} >
                                            <div >
                                                <img className="wing-creative-img" src="Images/services/idigital.png" />
                                            </div>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                       </Row>
                   </Container>
                </div>



                <div className="cloud-tech">
                    <Container  className="wing-creative-div">
                        <Row>
                            <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} >
                                <div className="cloud-tech-body">
                                    <div className="insame1">
                                        <div className="feature">
                                            <h2 className="eyebrow">
                                                <p>TECHNOLOGY </p>
                                            </h2>
                                        </div>
                                        <h3 className="feature-title h2">
                                            <p>Insame + AWS</p>
                                        </h3>
                                        <div className="feature-text">
                                            <p>We’ve invested in modern architecture on the #1 cloud platform:
                                                Amazon Web Services®.
                                                We take advantage of the massive amounts of R&D that AWS® has put
                                                into web services
                                                and security. Our cloud customers get the benefit of that
                                                relationship
                                            </p>
                                        </div>
                                        <div className="feature-action">
                                            <a href="#" className="cloud-tech-btn"
                                               target="_self">
                                                Take a Look
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12} >
                                <div className="cloud-tech-body">
                                    <div className="insame11">
                                        <img className="cloud-tech-img" src="Images/services/cloud-tech1.png"alt=""/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </Fragment>
        );
    }
}

export default Services;