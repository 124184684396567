import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";

class PrivacyPolicy extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Privacy Policy</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Privacy Policy
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="TopSection">
                    <Row className="d-flex justify-content-center">
                        <Col className="BetweenTwoSection" md={6} lg={6} sm={8} xs={12}>
                            <Card>
                                <Card.Body>

                                    <h5> In Progress </h5>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default PrivacyPolicy;