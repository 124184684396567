import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap'
import {Link} from "react-router-dom";

class Hardware extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="hadrware-bg-color">
                    <div className="parbase" role="none">
                        <div className="component-Hero intel-brand-ux component"
                             data-component="homepageHeroRefresh"
                             data-component-id="1" role="section">
                            <div className="insame-hard non-carousel blade solid theme-dark-default theme-blue-steel theme-light-1 color-block"
                                role="content" >
                                <div className="marquee-background embed-responsive embed-responsive-16by9 svgPattern theme-blue-steel">
                                    <div className="item" role="none">
                                        <div className="video-layer" role="img">
                                            <div className="layer-container video-still-image">
                                                 <span data-picture-sources="" role="none"> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-hero" role="none">
                                    <div className="marquee-layout" role="none">
                                        <div className="marquee-content" role="none">
                                            <div
                                                className="marquee-content-inner color-block-lighter has-spark spark-left group-link"
                                                role="content">

                                                <h2 className="eyebrow-hard">Hardware</h2>
                                                <h1 className="headline"> Insame is a Leader </h1>
                                                <p className="description">
                                                    We are providing best quality hardware products to our customers also most reasonable price.Products: Computers Parts, Office Equipment, Gaming PC, Security Camera & many other.
                                                </p>
                                                <span className="cta-item btn marquee-btn marquee-btn-primary"
                                                      aria-hidden="true">
                                                         <span>Visit Website <i className="fa fa-arrow-right"></i></span>
                                               </span>
                                            </div>
                                          </div>
                                        <div className="marquee-image image-wh" data-picture="" data-alt="Intel xeon">
                                            <div className="embed-responsive embed-responsive-16by9 ">
                                                <span className="image-container" data-picture-sources=" ">
                                                    <img src="Images/hardware/hardware-bg2.png" className="hardware-img" />
                                               </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default Hardware;