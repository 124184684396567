import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class Gallery extends Component {
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Gallery</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Gallery
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="BetweenTwoSection">
                    <Row className="d-flex justify-content-center">
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                           <Card className="image-box-gallery card" >
                               <img src="Images/gallery/IMG_3833.JPG" />
                           </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3833.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                 <img src="Images/gallery/IMG_3833.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3833.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3835.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3780.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3780.JPG" />
                            </Card>
                        </Col>
                        <Col key={1} xl={3} lg={3} md={3} sm={12} xs={12} >
                            <Card className="image-box-gallery card" >
                                <img src="Images/gallery/IMG_3780.JPG" />
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default Gallery;