import React, {Component, Fragment} from 'react';
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";

import FooterDesktop from "../components/common/FooterDesktop";

import CourseDetails from "../components/coursesdetails/CourseDetails";
import SuggestCourses from "../components/coursesdetails/SuggestCourses";

class CourseDetailsPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>
                <div className="Desktop">
                    <NavMenuDesktop/>
                </div>
                <div className="Mobile">
                    <NavMenuMobile/>
                </div>

                <CourseDetails/>
                <SuggestCourses/>


                <FooterDesktop/>
            </Fragment>
        );
    }
}

export default CourseDetailsPage;