import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

class IndustriesWeProvide extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="industry-bg">
                    <div className="industry-together-div">
                        <Row>
                            <h2 className="winning-together-heading text-center">Industries We Serve</h2>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col key={1} xl={2} lg={2} md={2} sm={2} xs={12} className="winning-together-col">
                                <Card className="industry-card">

                                    <div className="image-box">
                                        <img src="Images/industries/sme.png" />
                                    </div>
                                    <Card.Body>
                                        <h1 className="programmes-title">SME Business</h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </Fragment>
        );
    }
}

export default IndustriesWeProvide;