import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class UserOnBoard extends Component {
    render() {
        return (
            <Fragment>
                <Container className="TopSection">
                    <Row className="d-flex justify-content-center">
                        <Col className="BetweenTwoSection mobile-display" md={6} lg={6} sm={8} xs={12}>
                            <Card>
                                <Card.Body>

                                    <img className="login-page-img" src="Images/front_login.jpg" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="BetweenTwoSection" md={6} lg={6} sm={8} xs={12}>
                            <Card className="login-card">
                                <Card.Body>
                                    <Card.Title>STUDENT LOGIN</Card.Title>
                                    <p className="text-center login-subtitle">Please Enter Your Mobile No & Go  </p>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">

                                            <Form.Control type="text" placeholder="Your Mobile Number" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">

                                            <Form.Control type="text" placeholder="Your Password" />
                                        </Form.Group>
                                    </Form>
                                    <Button className="login-next-btn" variant="primary">Login</Button>
                                    <p className="text-center pt-3" >Forgot your password? </p>
                                    <p className="text-center" >Don't have an account? <Link to="/register">Register</Link></p>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>


            </Fragment>
        );
    }
}

export default UserOnBoard;