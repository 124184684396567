import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

class SuggestCourses extends Component {
    render() {
        return (
            <Fragment>
                <Container className="BetweenTwoSection">
                    <Row>
                        <h5 className="section-title">Recommended Courses </h5>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barpili.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার প্রিলি (MCQ) কোর্স </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৬০টি | ক্লাস টেস্ট: ২০টি | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <a href="#"><Button className="course-button-left" variant="danger">View Details</Button></a>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barpili.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার প্রিলি (MCQ) কোর্স </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৬০টি | ক্লাস টেস্ট: ২০টি | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <a href="#"><Button className="course-button-left" variant="danger">View Details</Button></a>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col key={1} xl={4} lg={4} md={6} sm={12} xs={12} >
                            <Card className="image-box card" >
                                <img src="Images/courses/barpili.jpg" />
                                <Card.Body>
                                    <h1 className="programmes-title">বার প্রিলি (MCQ) কোর্স </h1>
                                    <p className="programmes-short-des">আইন ক্লাস: ৬০টি | ক্লাস টেস্ট: ২০টি | মডেল টেস্ট: ১০টি </p>
                                    <a href="#"> <Button className="course-button-right" variant="danger">Enroll Now</Button></a>
                                    <a href="#"><Button className="course-button-left" variant="danger">View Details</Button></a>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default SuggestCourses;