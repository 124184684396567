import React, {Component, Fragment} from 'react';
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import CourseDetails from "../components/coursesdetails/CourseDetails";

import FooterDesktop from "../components/common/FooterDesktop";
import BarPili from "../components/cours/BarPili";

class BarPiliPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }
    render() {
        return (
            <Fragment>
                <div className="Desktop">
                    <NavMenuDesktop/>
                </div>
                <div className="Mobile">
                    <NavMenuMobile/>
                </div>


                <BarPili/>


                <FooterDesktop/>
            </Fragment>
        );
    }
}

export default BarPiliPage;