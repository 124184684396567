import React, {Component, Fragment} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class BigSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div className="big-slider">
                <Slider {...settings}>
                    <div >
                        <section className="homepageHero -whiteText">
                            <article className="homepageHero__content container responsive-article">
                                <p className="homepageHero__eyebrow text-left">ERP Simplified</p>
                                <h1 className="homepageHero__heading text-left">Smart. Modern.</h1>
                                <h2 className="homepageHero__subHeading text-left">Industry cloud solutions</h2>
                            </article>
                            <picture>
                                <source media="(max-width: 576px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 768px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1024px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1200px)"
                                                    srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1300px)"
                                                        srcSet="Images/slider/slider1.jpg"/>

                                <img className="homepageHero__mainImage responsive-slide"
                                                         src="Images/slider/slider1.jpg"/>

                            </picture>
                        </section>
                    </div>
                    <div>
                        <section className="homepageHero -whiteText">
                            <article className="homepageHero__content container responsive-article">
                                <p className="homepageHero__eyebrow text-left">ERP Simplified</p>
                                <h1 className="homepageHero__heading text-left">Smart. Modern.</h1>
                                <h2 className="homepageHero__subHeading text-left">Industry cloud solutions</h2>
                            </article>
                            <picture>
                                <source media="(max-width: 576px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 768px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1024px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1200px)"
                                        srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1300px)"
                                        srcSet="Images/slider/slider1.jpg"/>

                                <img className="homepageHero__mainImage responsive-slide"
                                     src="Images/slider/slider1.jpg"/>

                            </picture>
                        </section>
                    </div>
                    <div>
                        <section className="homepageHero -whiteText">
                            <article className="homepageHero__content container responsive-article">
                                <p className="homepageHero__eyebrow text-left">ERP Simplified</p>
                                <h1 className="homepageHero__heading text-left">Smart. Modern.</h1>
                                <h2 className="homepageHero__subHeading text-left">Industry cloud solutions</h2>
                            </article>
                            <picture>
                                <source media="(max-width: 576px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 768px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1024px)" srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1200px)"
                                        srcSet="Images/slider/slider1.jpg"/>
                                <source media="(max-width: 1300px)"
                                        srcSet="Images/slider/slider1.jpg"/>

                                <img className="homepageHero__mainImage responsive-slide"
                                     src="Images/slider/slider1.jpg"/>

                            </picture>
                        </section>
                    </div>

                </Slider>
            </div>
        );
    }
}

export default BigSlider;