import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class Register extends Component {
    render() {
        return (
            <Fragment>
                <Container className="TopSection">
                    <Row className="d-flex justify-content-center">
                        <Col className="BetweenTwoSection mobile-display" md={6} lg={6} sm={8} xs={12}>
                            <Card>
                                <Card.Body>
                                    <img className="login-page-img" src="Images/front_register.jpg" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="BetweenTwoSection" md={6} lg={6} sm={8} xs={12}>
                            <Card className="register-card">
                                <Card.Body>
                                    <Card.Title>STUDENT SIGN IN</Card.Title>
                                    <p className="text-center login-subtitle">Please Enter Your Mobile No & Go Next </p>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">

                                            <Form.Control type="text" placeholder="Enter your mobile number" />
                                        </Form.Group>
                                    </Form>
                                    <Button className="login-next-btn" variant="primary">Next</Button>
                                    <p className="text-center pt-4" >Already have an account? <Link to="/onboard">Login</Link></p>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>

            </Fragment>
        );
    }
}

export default Register;