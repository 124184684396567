import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";

class Notifications extends Component {

    constructor() {
        super();
        this.state={
            show:false,
        }
    }

    handleClose = () => {

        this.setState({show:false,})
    };
    handleShow = () => {
        this.setState({show:true,})

    };
    render() {
        return (
            <Fragment>
                <div className="page-header container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="page-header-content">
                                    <h1>Notification</h1>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item">
                                            Notification
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="BetweenTwoSection">
                    <Row>
                        <Col className=" d-flex justify-content-around p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text</h6>
                                    <p className="py-1  px-0 text-primary notification-date m-0"><i className="fa  fa-bell"/>  Date: 17-01-2023 | Status: Unread  </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className=" d-flex justify-content-around p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text</h6>
                                    <p className="py-1  px-0 text-success notification-date m-0"><i className="fa  fa-bell"/>  Date: 17-01-2023 | Status: Read  </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className=" d-flex justify-content-around p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text</h6>
                                    <p className="py-1  px-0 text-primary notification-date m-0"><i className="fa  fa-bell"/>  Date: 17-01-2023 | Status: Unread  </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className=" d-flex justify-content-around p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text</h6>
                                    <p className="py-1  px-0 text-success notification-date m-0"><i className="fa  fa-bell"/>  Date: 17-01-2023 | Status: Read  </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>We are sorry </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>The System is Under Developing </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default Notifications;